import React from 'react';
import { Typography } from '@material-ui/core';

const ContactFormSuccessful = (): JSX.Element => (
  <>
    <Typography component="h1" variant="h5" paragraph>
      Thank you!
    </Typography>
    <Typography variant="h5" color="textSecondary" paragraph>
      Your message has been successfully sent. We will contact you very soon!
    </Typography>
  </>
);

export default ContactFormSuccessful;
