import React, { SyntheticEvent } from 'react';
import { Container, Box, Typography } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';

import SEO from '../components/seo';
import FormPaper from '../components/account/form/form-paper';
import FormAvatar from '../components/account/form/form-avatar';
import ContactForm from '../components/Contact/ContactForm';
import ContactFormSuccessful from '../components/Contact/ContactFormSuccessful';

interface ValidateState {
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  message: boolean;
}

interface FormState {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
}

const formIsValid = ({ firstName, lastName, email, message }: ValidateState): boolean =>
  firstName && lastName && email && message;

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const emailIsValid = (email: string): boolean => emailRegex.test(email.toLowerCase());

const Contact = (): JSX.Element => {
  const [successful, setSuccessful] = React.useState(false);
  const [requestStatus, setRequestStatus] = React.useState({
    loading: false,
    error: false,
  });
  const [blur, setBlur] = React.useState<ValidateState>({
    firstName: false,
    lastName: false,
    email: false,
    message: false,
  });
  const [validate, setValidate] = React.useState<ValidateState>({
    firstName: false,
    lastName: false,
    email: false,
    message: false,
  });
  const [contact, setContact] = React.useState<FormState>({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  });

  const onBlur = (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const nextBlur = {
      ...blur,
      [e.currentTarget.name]: true,
    };
    setBlur(nextBlur);
    setValidate({
      ...validate,
      [e.currentTarget.name]:
        e.currentTarget.name === 'email'
          ? emailIsValid(e.currentTarget.value)
          : !!e.currentTarget.value.length,
    });
  };

  const onChange = (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>): void =>
    setContact({
      ...contact,
      [e.currentTarget.name]: e.currentTarget.value,
    });

  const onSubmit = React.useCallback(async () => {
    try {
      setRequestStatus({
        loading: true,
        error: false,
      });
      // const response = await fetch('/.netlify/functions/contact-form-email', {
      const response = await fetch(`${process.env.GATSBY_FUNCTIONS_URL}/contactFormEmail`, {
        method: 'POSt',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(contact),
      });
      const statusCode = await response.status;
      if (statusCode === 200) {
        setSuccessful(true);
      }
    } catch (error) {
      setRequestStatus({
        loading: false,
        error: true,
      });
    }
  }, [contact]);

  return (
    <>
      <SEO title="Contact" />
      <Box mb={5} mt={5}>
        <Container maxWidth="sm">
          <FormPaper>
            <FormAvatar>
              <MailIcon />
            </FormAvatar>
            {!successful && (
              <ContactForm
                contact={contact}
                validate={validate}
                blur={blur}
                isValid={formIsValid(validate)}
                requestStatus={requestStatus}
                onChange={onChange}
                onBlur={onBlur}
                onSubmit={onSubmit}
              />
            )}

            {successful && <ContactFormSuccessful />}

            {requestStatus.error && (
              <Typography variant="body2" color="textSecondary" component="p">
                An error occured while sending this form. Please try again.
              </Typography>
            )}
          </FormPaper>
        </Container>
      </Box>
    </>
  );
};

export default Contact;
