import React, { SyntheticEvent } from 'react';
import { Typography, Grid, FormControl, TextField, Box, Button } from '@material-ui/core';
import ButtonProgressContainer from '../account/form/button-progress-container';
import CircularProgressOverlay from '../account/form/circular-progress-overlay';
import ButtonSendIcon from './ButtonSendIcon';

interface RequestStatus {
  loading: boolean;
  error: boolean;
}

interface ValidateState {
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  message: boolean;
}

interface FormState {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
}

interface ContactFormProps {
  contact: FormState;
  validate: ValidateState;
  blur: ValidateState;
  isValid: boolean;
  requestStatus: RequestStatus;
  onChange: (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur: (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onSubmit: () => void;
}

const ContactForm = ({
  contact,
  validate,
  blur,
  isValid,
  requestStatus,
  onChange,
  onBlur,
  onSubmit,
}: ContactFormProps): JSX.Element => (
  <>
    <Typography component="h1" variant="h5">
      Contact us
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <FormControl margin="normal" required fullWidth>
          <TextField
            error={!validate.firstName && blur.firstName}
            autoComplete="fname"
            name="firstName"
            required
            fullWidth
            id="firstName"
            label="First name"
            value={contact.firstName}
            onChange={onChange}
            onBlur={onBlur}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl margin="normal" required fullWidth>
          <TextField
            error={!validate.lastName && blur.lastName}
            required
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            autoComplete="lname"
            value={contact.lastName}
            onChange={onChange}
            onBlur={onBlur}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          error={!validate.email && blur.email}
          helperText={!validate.email && blur.email ? 'Invalid email address' : undefined}
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={contact.email}
          onChange={onChange}
          onBlur={onBlur}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          error={!validate.message && blur.message}
          id="outlined-multiline-static"
          label="Message"
          variant="outlined"
          name="message"
          required
          fullWidth
          multiline
          rows="4"
          value={contact.message}
          onChange={onChange}
          onBlur={onBlur}
        />
      </Grid>
    </Grid>
    <Box mt={2} mb={2} width="100%">
      <ButtonProgressContainer fullWidth>
        <Button
          disabled={!isValid || requestStatus.loading}
          type="submit"
          onClick={onSubmit}
          fullWidth
          variant="contained"
          color="primary"
          component="button"
        >
          Submit <ButtonSendIcon />
        </Button>
        {requestStatus.loading && <CircularProgressOverlay size={30} />}
      </ButtonProgressContainer>
    </Box>
  </>
);

export default ContactForm;
